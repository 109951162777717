<template>
  <b-container fluid>
    <b-row v-if="spec == null && error == null" class="mt-5">
      <b-col class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </b-col>
    </b-row>

    <template v-if="spec">
      <b-row>
        <b-col>
          <h1>StatementSpec: {{ spec.StatementReference }} - {{ spec.Beneficiary.FullName }}</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card header="Rules" class="mt-4">
            <trx-accounting-rule-list :rules="spec.ReaccountRules" />
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
  export default {
    name: 'AccountingStatementSpec',
    components: {
      'trx-accounting-rule-list': () => import('@/components/TrxAccountingRuleList'),
    },
    data () {
      return {
        spec: null,
        error: null,
      }
    },
    props: {
      id: String
    },
    methods: {
    },
    mounted () {
      this.$http.get('accounting/statement-specs/' + this.id, {
          
        })
        .then(response => {
          this.spec = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            text: 'Er ging iets mis bij het laden'
          });
        });
    }
  }
</script>