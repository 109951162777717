var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _vm.spec == null && _vm.error == null
        ? _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c(
                "b-col",
                { staticClass: "text-center" },
                [_c("b-spinner", { attrs: { label: "Spinning" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.spec
        ? [
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c("h1", [
                    _vm._v(
                      "StatementSpec: " +
                        _vm._s(_vm.spec.StatementReference) +
                        " - " +
                        _vm._s(_vm.spec.Beneficiary.FullName)
                    )
                  ])
                ])
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-card",
                      { staticClass: "mt-4", attrs: { header: "Rules" } },
                      [
                        _c("trx-accounting-rule-list", {
                          attrs: { rules: _vm.spec.ReaccountRules }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }